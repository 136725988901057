
import React from "react";
import styled from "styled-components";
import { Card } from "../../components/card";
import { CustomText } from "../../components/text";


class GetOnboarded extends React.Component {


    render() {
        return (
            <GetOnboardedContainer>
                {/* don't worry if you dont know how to do anything. That is why we are here */}
                {/* To begin with sign up to create an account. if you already have an account, login */}
                {/* create a new topic */}
                {/* put in details to the topic */}
                {/* create it*/}
                <Card className="landing" width="100%" height="50vh" radius="0">
                    <div>
                        <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="50px">
                        Don't worry if you can't figure anything out yet.
                        </CustomText>
                        <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="30px" fontWeight="normal">
                            We are more than willing to help out. 
                        </CustomText>
                    </div>

                </Card>

            </GetOnboardedContainer>
        )
    }

}

export default GetOnboarded;

const GetOnboardedContainer = styled.div`

    .landing{
        &>div{
            position: absolute;
            top: 25vh;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        background-image: radial-gradient(circle at center center, 
            rgba(33,33,33,0),rgb(33,33,33)),
            repeating-linear-gradient(135deg, 
                rgb(33,33,33) 0px, 
                rgb(33,33,33) 1px,transparent 1px, 
                transparent 4px),
            repeating-linear-gradient(45deg, rgb(56,56,56) 0px, 
            rgb(56,56,56) 5px,transparent 5px, transparent 6px),
            linear-gradient(90deg, rgb(33,33,33),rgb(33,33,33)
            );
    }

`